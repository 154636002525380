(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
$(document).ready(() => {
    //validation for leads-form
	   function scrollForWelcome() {
        //move control to top when click next or previous button
        $('html, body').animate({ scrollTop: $(".HWP-main-container").offset().top })
    }
    //take the session values
    var formData = new Object();

    //section two element - About Us
    let Primary_Heating_Source__c = null;
    let HWP_Lead_Source__c = null;
    let Other_Lead_Source__c = null;
    let Promo_code__c = null;

    //section three - Your Household
    let Government_Benefit__c = null; //cookies need to check
    let People_In_Household__c = null; //cookies need to check

    let Income_Eligible__c = null;

    //sectionfour - your Home
    let Own_or_Rent__c = null; //cookies need to check

    let Res_Building_Type__c = null; //cookies need to check

    let Year_Built__c = null;
    let Landlord_Name__c = null;
    let Landlord_Phone__c = null;
    let Landlord_Email__c = null;
    let Landlord_Contact_Method__c = null;

    //section five - contact us
    let Contract_Number__c = null;
    let Contact_Name__c = null;
    let Service_Address__c = null;
    let Service_Address2__c = null;
    let Service_Postal_Code__c = null;
    let Service_City__c = null;
    let Contact_Email__c = null;
    let Contact_Best_Time__c = null;
    let Contact_Phone__c = null;
    let MarketingMaterials__c = null;
    let FirstName = null;
    let LastName = null;
    
    function checkNull(item) {
        if (item === null || item === undefined || item === "") {
            return true;
        } else {
            return false;
        }
    }
    
    function guradianMapping() {
 
        //section two element - About Us
        Primary_Heating_Source__c = sessionStorage.getItem(
            "Primary_Heating_Source__c"
        );
        HWP_Lead_Source__c = sessionStorage.getItem("HWP_Lead_Source__c");
        Other_Lead_Source__c = sessionStorage.getItem("Other_Lead_Source__c");
        Promo_code__c = sessionStorage.getItem("Promo_code__c");

        //section three - Your Household
        Government_Benefit__c = sessionStorage.getItem("Government_Benefit__c");
        People_In_Household__c = sessionStorage.getItem("People_In_Household__c")
    
        if (!checkNull(People_In_Household__c)) {
            Income_Eligible__c = sessionStorage.getItem(
                `Income_Eligible__c_${People_In_Household__c}`
            );
        }

        //sectionfour - your Home
        Own_or_Rent__c = checkNull(Own_or_Rent__c)
            ? sessionStorage.getItem("Own_or_Rent__c")
            : Own_or_Rent__c; //cookies need to check
        Res_Building_Type__c =  sessionStorage.getItem("Res_Building_Type__c")
 
        Year_Built__c = sessionStorage.getItem("Year_Built__c");
        Landlord_Name__c = sessionStorage.getItem("Landlord_Name__c");
        Landlord_Phone__c = sessionStorage.getItem("Landlord_Phone__c");
        Landlord_Email__c = sessionStorage.getItem("Landlord_Email__c");
        Landlord_Contact_Method__c = sessionStorage.getItem(
            "Landlord_Contact_Method__c"
        );

        //section five - contact us
        Contract_Number__c = sessionStorage.getItem("LeadFormData_AccountNumber");
        Contact_Name__c =
            sessionStorage.getItem("LeadFormData_FirstName") +
            " " +
            sessionStorage.getItem("LeadFormData_LastName");
        Service_Address__c = sessionStorage.getItem("LeadFormData_StreetAddress");
        Service_Address2__c = sessionStorage.getItem("LeadFormData_StreetAddress2");
        Service_Postal_Code__c = sessionStorage.getItem("LeadFormData_PostalCode");
        Service_City__c = sessionStorage.getItem("LeadFormData_City");
        Contact_Email__c = sessionStorage.getItem("LeadFormData_EmailAddress");      
        Contact_Phone__c = sessionStorage.getItem("LeadFormData_Phone");
        Contact_Best_Time__c = sessionStorage.getItem("LeadFormData_BestContactTime");
        MarketingMaterials__c = $('[name="LeadFormData_UserAcceptance"]').prop('checked');
        FirstName = sessionStorage.getItem("LeadFormData_FirstName");
        LastName = sessionStorage.getItem("LeadFormData_LastName");

        //take the session value and convert it into guardian key
        //make a ajax call and sent it to guardian
        formData = { "__RequestVerificationToken": $("input[name=__RequestVerificationToken]").val() };
        formData.Primary_Heating_Source__c = Primary_Heating_Source__c;
        
        formData.Other_Lead_Source__c = Other_Lead_Source__c;
        formData.HWP_Lead_Source__c = HWP_Lead_Source__c;
        formData.Promo_code__c = Promo_code__c;
        formData.Government_Benefit__c = Government_Benefit__c;
        formData.People_In_Household__c = People_In_Household__c;
        formData.Income_Eligible__c = Income_Eligible__c;
        formData.Res_Building_Type__c = Res_Building_Type__c;
        formData.Own_or_Rent__c = Own_or_Rent__c;
        formData.Year_Built__c = Year_Built__c;
        formData.Landlord_Name__c = Landlord_Name__c;
        formData.Landlord_Email__c = Landlord_Email__c;
        formData.Landlord_Phone__c = Landlord_Phone__c;
        formData.Landlord_Contact_Method__c = Landlord_Contact_Method__c;
        formData.Contract_Number__c = Contract_Number__c;
        formData.Contact_Name__c = Contact_Name__c;
        formData.Service_Address__c = Service_Address__c;
        formData.Service_Address2__c = Service_Address2__c;
        formData.Service_Postal_Code__c = Service_Postal_Code__c;
        formData.Service_City__c = Service_City__c;
        formData.Contact_Email__c = Contact_Email__c;
        formData.Contact_Best_Time__c = Contact_Best_Time__c;
        formData.Contact_Phone__c = Contact_Phone__c;
        formData.MarketingMaterials__c = MarketingMaterials__c;
        formData.FirstName = FirstName;
        formData.LastName = LastName;        
        
    }
    
    $(".final-submit-button").on("click", () => {
        guradianMapping();

        $(".final-submit-button").prop("disabled", true);      
        $.ajax({

            type: "POST",         
            data: formData,
            url: "/api/HWPLeadForm/CreateHWPLead",
            dataType: "json",          
        })

            .then((response) => {
                if (response) {

                    if (response.Status === 400) {
                        $(".final-submit-button").prop("disabled", false);
                        $(".welcome-message-container").html(response.ViewString);
                        $("#finalSubmitModal").modal("hide");
                        scrollForWelcome()
                        $(".hwp-main-content-area").hide();
                        $(".welcome-message-container").show();
                    }
                    else {

                        $(".final-submit-button").prop("disabled", false);
                        $(".welcome-message-container").html(response.ViewString);
                        $("#finalSubmitModal").modal("hide");
                        scrollForWelcome()
                        $(".hwp-main-content-area").hide();
                        $(".welcome-message-container").show();


                        //GA Code
                        window.dataLayer.push({
                            'event': 'Screen Tracking',
                            'Screen_completed': 'Lead Submitted',
                            'Page': 'HomeWinterproofing'
                        });

                        sessionStorage.clear();
                    }
                }
              })
    
            .catch((err) => {
                let msg = $("#antyforgery-Msg").text();
                $(".final-submit-button").prop("disabled", false);
                if (err.responseText.includes('Rate limit reached')) {
                    $(".welcome-message-container").html(`<div class="container bg-white welcome-message-box"><h3><strong></strong></h3></div>`);
                }
                else {
                    $(".welcome-message-container").html(`<div class="container bg-white welcome-message-box"><h3><strong>${msg}</strong></h3></div>`);
                }            
                
                $("#finalSubmitModal").modal("hide");
                scrollForWelcome()
                $(".hwp-main-content-area").hide();
                $(".welcome-message-container").show();
            });
    });
});

},{}],2:[function(require,module,exports){
$(document).ready(() => {
    $("#div-global-loader-box").show();
    //attribute and its usage
    //data-progress - detect the section - applied on the section div
    //data-content - detect the progress bar active state - applied on the li element of the progress list
    //data-validation - contain id of the question which need to be validated
    //data-parent - applied on question block to make it unique
    //data-child - applid on the select option field - used to map with the data-parent
    $("[name='Own_or_Rent__c']").parent().css("margin-top", "1rem");

    $("[name='Year_Built__c']").attr("maxlength", "4");
    $("[name='Promo_code__c']").attr("maxlength", "80");
    $captcha_key = $('#reCaptchaDiv').val();

    function scrollToTop() {
        //move control to top when click next or previous button
        $('html, body').animate({ scrollTop: $(".list-container").offset().top })
    }


    function makeCookiesLabelActive(element) {
        //label will show only when we have cookies
        let label = $(element).parent().parent().find(".cookies-label");
        let name = $(element).attr("name");
        let id = $(element).parent().parent().attr("id");
        $(label).addClass("active");

    }
    function makeCookiesLabelDeactive(element) {
        let label = $(element).parent().parent().find(".cookies-label"); $(label).removeClass("active");
    }


    $("#LeadFormData_Phone").on('input', function (e) {
        let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    });

    $("[name='Landlord_Phone__c']").on('input', function (e) {
        let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    });

    function hideAllIncomeQuestion() {
        for (let i = 1; i < 8; i++) {
            $(`[name="Income_Eligible__c_${i}"]`).parent().removeClass("active");
        }
    }

    function check_cookie_name(name) {
        let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) {
            return match[2];
        }
        else {
            return false;
        }
    }

    var peopleCount = null; //variable to hold people Count value which will be used for appearing income related question

    //hit the people count api on page load and check if user has any cookies value for people if yes then make income question active
    $.get("/api/DSMCookie/GetPeopleCountFromCookie", function (result) {
        if (result && result.length > 0) {
            sessionStorage.setItem("People_In_Household__c", result);
            peopleCount = result;
            $(`[name="People_In_Household__c"]`).parent().addClass("active");
            $("#div-global-loader-box").show();
            makeIncomeQuestionFilledAndActive();

        }
    });



    //make income question active based on the people count cookies value
    function makeIncomeQuestionFilledAndActive() {
        let peopleSession = sessionStorage.getItem("People_In_Household__c"); //check if we have session value for people in household
        if (peopleCount && peopleCount.length > 0 || peopleSession && peopleSession.length > 0) {
            $.get("/api/DSMCookie/GetCookieForCombinedIncome", function (result) {
                if (result && result.length > 0) {
                    $("#div-global-loader-box").show();
                    sessionStorage.setItem(`Income_Eligible__c_${peopleCount}`, result)
                    $(`[name="Income_Eligible__c_${peopleCount}"]`).val(result);//setting yes or no in the income question
                    $(`[name="Income_Eligible__c_${peopleCount}"]`).parent().addClass("active"); //make the question active


                }
                else {
                    $("#div-global-loader-box").show();
                    $(`[name="Income_Eligible__c_${peopleCount}"]`).parent().addClass("active"); //make the question active

                }
            });
        }

    }

    function checkHomeValue(str) {
        switch (str) {
            case "Detached":
                return true;
                break;
            case "Semi-detached":
                return true;
                break;
            case "Row house/town house":
                return true;
                break;
        }
    }



    //making leads foirm container show once you click 4th screen next b utton
    $("[data-progress='4'] .next").on("click", function () {
        let form = $(".leads-form-container");
        $(form).insertBefore($("[data-progress='5'] .hwp-button-container"))
        $("[data-progress='5'] .leads-form-container").show();
    })
    //making first content container active
    $(".content-container").first().addClass("active");



    //setting values to input/select field from session or cookies (set all the values except the income question)  
    const inputControls = $(".hwp-input-control");

    for (let i = 0; i < inputControls.length; i++) {
        let session_name = $(inputControls[i]).attr("name");
        let temp = sessionStorage.getItem(session_name);

        //check session values based on name, if has session value update the field
        if (temp && temp.length > 0) {
            $(inputControls[i]).val(temp);
        }

        switch (session_name) {
            case "Government_Benefit__c":
                $.get('/api/DSMCookie/GetGovtBenefitFromCookie', function (result) {
                    if (result && result.length > 0) {
                        $(`[name=${session_name}]`).val(result);
                        $("#div-global-loader-box").show();
                        sessionStorage.setItem("Government_Benefit__c", result);
                        makeCookiesLabelActive($(`[name=${session_name}]`));
                    }
                    else {
                        makeCookiesLabelDeactive($(`[name=${session_name}]`))
                        $("#div-global-loader-box").show();
                    }
                })
                break;

            case "People_In_Household__c":
                $.get('/api/DSMCookie/GetPeopleCountFromCookie', function (result) {
                    if (result && result.length > 0) {
                        $("#div-global-loader-box").show();
                        sessionStorage.setItem("People_In_Household__c", result);
                        $(`[name=${session_name}]`).val(result)

                    }

                })
                break;

            case "Res_Building_Type__c":
                $.get('/api/DSMCookie/GetHouseTypeForHWPFromCookie', function (result) {
                    if (result && result.length > 0) {
                        if (checkHomeValue(result)) {
                            $("#div-global-loader-box").show();
                            sessionStorage.setItem("Res_Building_Type__c", result);
                            $(`[name=${session_name}]`).val(result)
                            makeCookiesLabelActive($(`[name=${session_name}]`));
                        }
                        else {
                            sessionStorage.setItem("Res_Building_Type__c", "");
                            $(`[name=${session_name}]`).val("")
                            $("#div-global-loader-box").show();
                            makeCookiesLabelDeactive($(`[name=${session_name}]`));
                        }
                    }
                    else {
                        makeCookiesLabelDeactive($(`[name=${session_name}]`))
                        $("#div-global-loader-box").show();
                    }
                })
                break;



            case "Enbridge_Customer":
                $.get('/api/DSMCookie/GetCookieForEnbridgeCustomer', function (result) {
                    if (result && result.length > 0) {
                        $("#div-global-loader-box").show();
                        $(`[name=${session_name}]`).val(result);
                        sessionStorage.setItem("Enbridge_Customer", result);
                        makeCookiesLabelActive($(`[name=${session_name}]`));
                    }
                    else {
                        makeCookiesLabelDeactive($(`[name=${session_name}]`))
                        $("#div-global-loader-box").show();
                    }
                })
                break;

        }
    }


    //making dynamic question active based on the session value
    const subQuestion = $(".sub-question-container");
    for (let i = 0; i < subQuestion.length; i++) {
        let input = $(subQuestion[i]).find("input");
        let select = $(subQuestion[i]).find("select");
        let name = null;
        let sessionValue = null;
        if ((input && input.length > 0) || (select && select.length > 0)) {
            name = $(input).attr("name") || $(select).attr("name");
            sessionValue = sessionStorage.getItem(name);
            if (
                (sessionValue && sessionValue.length > 0) ||
                check_cookie_name(name)
            ) {
                $(subQuestion[i]).addClass("active");
                //it will make all sub-question active
            }
        }
    }

    //making dynamic question ative if its parent question has related values
    //check parent question if values is there and values is related to dynamic question element then make the dynamic question active

    const parentElement = $("[data-question]");//pick all div which has data-question attribute
    for (let i = 0; i < parentElement.length; i++) {
        let input = $(parentElement[i]).find("input");
        let select = $(parentElement[i]).find("select");
        let value = null;
        let name = null;
        if ((input && input.length > 0) || (select && select.length > 0)) {
            value = $(input).val() || $(select).val(); //get input control value
            name = $(input).attr("name") || $(select).attr("name"); //get input control name
            let sessionDyn = sessionStorage.getItem(`${name}_dynamic`);//get the dynamic session value which will tell us if we value is related to dynamic question or not

            if ((value && value.length > 0)) {
                let dynarr = null;
                let question = $(parentElement[i]).attr("data-question");
                if (question && question.length > 0 && sessionDyn === "1") {

                    dynarr = question.split(",");
                    makeQuestionAppear(dynarr);
                    if (name === "Government_Benefit__c" || name === "People_In_Household__c") {
                        hideAllIncomeQuestion();
                        makeIncomeQuestionFilledAndActive();
                    }

                }
                else {
                    if (question && question.length > 0) {
                        dynarr = question.split(",");
                        hideRelatedQuestion(dynarr)
                    }
                }
            }


        }
    }

    function makeProgressActive(id) {
        $(".nav-list li").removeClass("active");
        let container = $(`[data-id=${id}]`);//get element based on ID 
        let progress = $(container).attr("data-progress"); //get progress value of the same element
        $(`[data-content=${progress}]`).addClass("active"); //make progress bar active based on the progress value received from element
    }
    function checkValidation(arr, modal = null) {
        let status = true;
        for (let i = 0; i < arr.length - 1; i++) {
            if (arr[i].length > 0) {
                let element = $(`#${arr[i]}`);
                //take the required field value from the question and check weather that field is required or not
                let required = $(`#${arr[i]}`).attr("data-required");
                let subQuestionStatus = $(element).parent().hasClass("sub-question-container");
                if (($(element).parent().hasClass("sub-question-container") && $(element).parent().hasClass("active") && required === "true")
                    || ((required === "true" && subQuestionStatus === false))) {

                    //don't apply any validation on dynamic question
                    let val = $(`#${arr[i]}`).val();
                    let msg = $("option:selected", $(`#${arr[i]}`)).attr("data-message");


                    //if required and do not have any value show error
                    if (val === undefined || val === null || val === "") {
                        status = status && false;
                        //clearModalText();
                        showError(arr[i], null, modal);
                    } else {

                        //if user pick some options which is not applicable as per the given program
                        if (msg === null || msg === '' || msg === undefined) {
                            status = status && true;
                        }
                        else {
                            //has value but also has some message to display on popup
                            status = status && false;
                            clearModalText();
                            showError(arr[i], msg, modal);
                        }
                    }

                }
                else {
                    //check weather element is required or not

                    status = status && true;

                }
            }
        }
        return status;
    }

    function clearModalText() {
        $(" .modal-body p").html("");
    }
    function showError(element, msg = null, modal) {
        if (msg === null) {
            if (modal === null) {

            }

            else {
                let msg = $(`#${modal}_validationModal .modal-body`).attr("data-required-msg");
                $(`#${modal}_validationModal .modal-body div.validate-content`).html(msg);
                $(`#${modal}_validationModalCloseButton`).show();
                $(`#${modal}_validationModalButton`).show();
            }

            if (element === null) {

            }
            else {
                let validation = $(`#${element}`).parent().find(".hwp-error").attr("data-val-message");
                $(`#${element}`).parent().find(".hwp-error").text(validation).addClass("active");
            }


        }
        else {
            if (modal === null) {

            }
            else {
                $(`#${modal}_validationModal .modal-body div.validate-content`).html(msg);
                $(`#${modal}_validationModalCloseButton`).show();
                $(`#${modal}_validationModalButton`).hide();
                let elementName = $(`#${element}`).attr("name");
                //GA code
                window.dataLayer.push({
                    'event': 'Screen Tracking',
                    'Screen_completed': elementName,
                    'Page': 'HomeWinterproofing'
                });
            }

        }
    }
    function hideError(element) {
        $(`#${element}`).parent().find(".hwp-error").removeClass("active");
    }

    window.Parsley.addValidator("confirm", {
        validateString: function (value) {
            let compareValue = $("#LeadFormData_EmailAddress").val();
            if (compareValue && compareValue.length > 0) {
                if (value === compareValue) {
                    $("#LeadFormData_EmailAddress").removeClass("parsley-error");
                    $("#LeadFormData_EmailAddress + ul").removeClass("filled");
                    return true;
                }
                else {
                    return false;
                }
            }
        }, messages: { en: $(".confirmemail-not-matched").text(), },
    });


    window.Parsley.addValidator("confirmemail", {
        validateString: function (value) {
            let compareValue = $("#LeadFormData_ConfirmEmailAddress").val();
            if (compareValue && compareValue.length > 0) {
                if (value === compareValue) {
                    $("#LeadFormData_ConfirmEmailAddress").removeClass("parsley-error");
                    $("#LeadFormData_ConfirmEmailAddress + ul").removeClass("filled");
                    return true;
                }
                else {

                    return false;
                }

            }
        }, messages: { en: $(".email-not-matched").text(), },
    });

    function checkInput(id) {
        let element = $(id).parsley();
        return element.validate() === true;

    }

    function checkFormValidation() {
        if (checkInput("#LeadForm")) {
            return true;
        }
        else {
            return false;
        }

    }


    $(".lead-form-next").on("click", function (e) {
        e.preventDefault();

        if (checkFormValidation()) {
            var reCaptchaDisabled = $("#reCaptchaDiv").val() == null || $("#reCaptchaDiv").val() == undefined;
            if (!reCaptchaDisabled) {
                grecaptcha.ready(function () {
                    var siteKey = $('#reCaptchaDiv').val();
                    grecaptcha.execute(siteKey, { action: 'HWPForm' }).then(function (token) {
                        SubmitLeadForm(token);
                    });
                });
            }
            else {
                SubmitLeadForm('');
            }
        }

        else {

            showError(null, null, 5);
            $("#5_validationModal").modal('show');
        }

    })

    function SubmitLeadForm(reCaptchaValidationResponse) {

        $.ajax({
            type: "POST",
            data: { 'ReCaptchaValidationResponse': reCaptchaValidationResponse },
            dataType: "json",
            url: "/api/HWPLeadForm/IsFormRecaptchaInValid",


        })
            .then((data) => {

                if (data.status === "true" || data.status === true) {
                    let id = $(".lead-form-next").attr("data-next");
                    let ganame = $(".lead-form-next").attr("data-gaitem");
                    scrollToTop();
                    makeProgressActive(id);
                    $(".content-container").removeClass("active");
                    $(`[data-id=${id}]`).addClass("active");
                    //GA code
                    window.dataLayer.push({
                        'event': 'Screen Tracking',
                        'Screen_completed': ganame,
                        'Page': 'HomeWinterproofing'
                    });
                }
                else {

                    return false;
                }

                })
                .catch((err) => {
                    return false;
                })
            }


        $("[data-target='#finalSubmitModal']").on("click", function () {
                //GA code for the final submit modal
                window.dataLayer.push({
                    'event': 'Screen Tracking',
                    'Screen_completed': 'Review',
                    'Page': 'HomeWinterproofing'
                });

            })
        $("button.next").on("click", function () {
                let id = $(this).attr("data-next");
                let modal = $(this).attr("data-valmodel");
                let validation = $(this).attr("data-validation");

                let emailinvalmsg = $(this).attr("data-email-valmessage");
                let nameinvalmsg = $(this).attr("data-landlord-valmessage");
                let phoneinvalmsg = $(this).attr("data-phone-valmessage")
                let ganame = $(this).attr("data-gaitem");
                let email = $("[name='Landlord_Email__c']").val();
                let landlordName = $("[name='Landlord_Name__c']").val();
                let landlordPhone = $("[name='Landlord_Phone__c']").val();
                if (validation && validation.length > 1) {
                    let Validationarray = validation.split(",");
                    if (checkValidation(Validationarray, modal)) {
                        if (email && email.length > 0 && modal === '4') {
                            if (testEmail(email)) {
                                if (landlordName && landlordName.length > 0) {
                                    if (testName(landlordName)) {
                                        if (landlordPhone && landlordPhone.length > 0) {
                                            if (testPhone(landlordPhone)) {
                                                makeProgressActive(id);
                                                scrollToTop();
                                                $(".content-container").removeClass("active");
                                                $(`[data-id=${id}]`).addClass("active");
                                                //GA code
                                                window.dataLayer.push({
                                                    'event': 'Screen Tracking',
                                                    'Screen_completed': ganame,
                                                    'Page': 'HomeWinterproofing'
                                                });

                                            }
                                            else {
                                                $("[name='Landlord_Phone__c'] + .hwp-error").text(phoneinvalmsg);
                                                $("[name='Landlord_Phone__c'] + .hwp-error").addClass("active");
                                                $(`#${modal}_validationModal`).modal("show");
                                            }
                                        }
                                        else {
                                            makeProgressActive(id);
                                            scrollToTop();
                                            $(".content-container").removeClass("active");
                                            $(`[data-id=${id}]`).addClass("active");
                                            //GA code
                                            window.dataLayer.push({
                                                'event': 'Screen Tracking',
                                                'Screen_completed': ganame,
                                                'Page': 'HomeWinterproofing'
                                            });
                                        }

                                    }
                                    else {
                                        $("[name='Landlord_Name__c'] + .hwp-error").text(nameinvalmsg);
                                        $("[name='Landlord_Name__c'] + .hwp-error").addClass("active");
                                        $(`#${modal}_validationModal`).modal("show");

                                    }
                                }
                                else {
                                    makeProgressActive(id);
                                    scrollToTop();
                                    $(".content-container").removeClass("active");
                                    $(`[data-id=${id}]`).addClass("active");
                                    //GA code
                                    window.dataLayer.push({
                                        'event': 'Screen Tracking',
                                        'Screen_completed': ganame,
                                        'Page': 'HomeWinterproofing'
                                    });
                                }


                            }
                            else {
                                $("[name='Landlord_Email__c'] + .hwp-error").text(emailinvalmsg);
                                $("[name='Landlord_Email__c'] + .hwp-error").addClass("active");
                                $(`#${modal}_validationModal`).modal("show");
                            }
                        }
                        else {
                            makeProgressActive(id);
                            scrollToTop();
                            $(".content-container").removeClass("active");
                            $(`[data-id=${id}]`).addClass("active");
                            //GA code
                            window.dataLayer.push({
                                'event': 'Screen Tracking',
                                'Screen_completed': ganame,
                                'Page': 'HomeWinterproofing'
                            });
                        }
                    } else {
                        $(`#${modal}_validationModal`).modal("show");
                    }
                }
                else {

                    makeProgressActive(id);
                    $(".content-container").removeClass("active");
                    $(`[data-id=${id}]`).addClass("active");
                    //GA code
                    window.dataLayer.push({
                        'event': 'Screen Tracking',
                        'Screen_completed': ganame,
                        'Page': 'HomeWinterproofing'
                    });

                }
            });



        function testEmail(email) {
            let re = /^((([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))$/;
            return re.test(email);
        }

        function testPhone(phone) {
            if (phone && phone.length === 14) {
                return true;
            }
            else {
                return false;
            }
        }

        function testName(name) {
            let re = /^[a-zA-Z,. _éàèùçâêîôû'ëïü-]*$/;
            return re.test(name);
        }

        $("button.prev").on("click", function () {

            let id = $(this).attr("data-prev");

            makeProgressActive(id);
            scrollToTop();
            $(".content-container").removeClass("active");
            $(`[data-id=${id}]`).addClass("active");
        });

        function makeQuestionAppear(arr) {
            if (arr && arr.length > 0) {
                for (let i = 0; i < arr.length - 1; i++) {
                    $(`[data-parent=${arr[i]}]`).addClass("active");
                }
            }
        }



        $("select.hwp-input-control").on("change", function () {
            let val = $(this).val();
            let name = $(this).attr("name"); //1c-select-name
            let dynarr = null;

            let arr = $(this).parent().attr("data-question"); //this will hide all the related question belongs to this select dropdown
            if (arr && arr.length > 1) {
                dynarr = arr.split(",");
                hideRelatedQuestion(dynarr);
            }
            if (val === undefined || val === null || val === "") {
                showError($(this).attr("id"));
                sessionStorage.setItem(name, '');
            } else {

                hideError($(this).attr("id"));
                sessionStorage.setItem(name, val);

                let option = $("option:selected", this).attr("data-child");
                if (option && option.length > 0) {
                    if (option === "00000000000000000000000000000000") {
                        sessionStorage.setItem(`${name}_dynamic`, "0");
                        //delete related cookies
                        if (name === "Government_Benefit__c") {
                            deleteUnwantedCookies("People_In_Household__c");
                            deleteUnwantedCookies("Income_Eligible__c_1");
                        }
                    }
                    else {
                        $(`[data-parent=${option}]`).addClass("active");
                        sessionStorage.setItem(`${name}_dynamic`, "1");

                    }

                }


                if (val === "Rent" && option === "00000000000000000000000000000000") {
                    makeQuestionAppear(dynarr);
                    sessionStorage.setItem(`${name}_dynamic`, "1");
                }

                //setting cookies
                switch (name) {
                    case "Government_Benefit__c":
                        $.get("/api/DSMCookie/SaveGovtBenefitInCookie", { govtBenefit: val });
                        break;

                    case "People_In_Household__c":
                        $.get("/api/DSMCookie/SavePeopleCountInCookie", { peopleCount: val });
                        break;

                    case "Income_Eligible__c_1":
                        $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                            incomeValue: val,
                        });
                        break;
                    case "Income_Eligible__c_2":
                        $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                            incomeValue: val,
                        });
                        break;
                    case "Income_Eligible__c_3":
                        $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                            incomeValue: val,
                        });
                        break;
                    case "Income_Eligible__c_4":
                        $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                            incomeValue: val,
                        });
                        break;
                    case "Income_Eligible__c_5":
                        $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                            incomeValue: val,
                        });
                        break;
                    case "Income_Eligible__c_6":
                        $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                            incomeValue: val,
                        });
                        break;
                    case "Income_Eligible__c_7":
                        $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                            incomeValue: val,
                        });
                        break;

                    case "Res_Building_Type__c":
                        $.get("/api/DSMCookie/SaveHouseTypeForHWPInCookie", {
                            houseType: val,
                        });
                        break;

                    case "Enbridge_Customer":
                        $.get("/api/DSMCookie/SaveCookieForEnbridgeCustomer", {
                            enbridgeCustomer: val,
                        });
                        break;

                    case "Own_or_Rent__c":
                        $.get("/api/DSMCookie/SaveCookieForResidenceType", {
                            residenceType: val,
                        });
                        break;
                }
            }
        });


        function deleteUnwantedCookies(name) {
            //setting cookies
            switch (name) {
                case "Government_Benefit__c":
                    $.get("/api/DSMCookie/SaveGovtBenefitInCookie", { govtBenefit: '' });
                    break;

                case "People_In_Household__c":
                    $.get("/api/DSMCookie/SavePeopleCountInCookie", { peopleCount: '' });
                    break;

                case "Income_Eligible__c_1":
                    $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                        incomeValue: '',
                    });
                    break;
                case "Income_Eligible__c_2":
                    $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                        incomeValue: '',
                    });
                    break;
                case "Income_Eligible__c_3":
                    $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                        incomeValue: '',
                    });
                    break;
                case "Income_Eligible__c_4":
                    $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                        incomeValue: '',
                    });
                    break;
                case "Income_Eligible__c_5":
                    $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                        incomeValue: '',
                    });
                    break;
                case "Income_Eligible__c_6":
                    $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                        incomeValue: '',
                    });
                    break;
                case "Income_Eligible__c_7":
                    $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                        incomeValue: '',
                    });
                    break;

                case "Res_Building_Type__c":
                    $.get("/api/DSMCookie/SaveHouseTypeForHWPInCookie", {
                        houseType: '',
                    });
                    break;

                case "Enbridge_Customer":
                    $.get("/api/DSMCookie/SaveCookieForEnbridgeCustomer", {
                        enbridgeCustomer: '',
                    });
                    break;

                case "Own_or_Rent__c":
                    $.get("/api/DSMCookie/SaveCookieForResidenceType", {
                        residenceType: '',
                    });
                    break;
            }
        }

        //getting the session values
        $("input").on("input", function (event) {
            let val = $(this).val();
            let name = $(this).attr("name");//1c-select-name   
            if (val === undefined || val === null || val === "") {
                showError($(this).attr("id"));
                sessionStorage.setItem(name, '');
            } else {
                hideError($(this).attr("id"));

                if (name === 'Landlord_Email__c') {
                    if (testEmail(val)) {
                        $("[name='Landlord_Email__c'] + .hwp-error").removeClass("active");
                    }
                    else {
                        let msg = $("[data-valmodel='4']").attr("data-email-valmessage");
                        $("[name='Landlord_Email__c'] + .hwp-error").text(msg).addClass("active");
                    }
                }
                if (name === 'Landlord_Name__c') {
                    if (testName(val)) {
                        $("[name='Landlord_Name__c'] + .hwp-error").removeClass("active");
                    }
                    else {
                        let msg = $("[data-valmodel='4']").attr("data-landlord-valmessage");
                        $("[name='Landlord_Name__c'] + .hwp-error").text(msg).addClass("active");
                    }
                }

                if (name === 'Landlord_Phone__c') {
                    if (testPhone(val)) {
                        $("[name='Landlord_Phone__c'] + .hwp-error").removeClass("active");
                    }
                    else {
                        let msg = $("[data-valmodel='4']").attr("data-phone-valmessage");
                        $("[name='Landlord_Phone__c'] + .hwp-error").text(msg).addClass("active");
                    }
                }
                sessionStorage.setItem(name, val);
            }
        });

        $("[name='Build_Unknown__c']").on("change", function () {

            if (this.checked) {
                $("[name='Year_Built__c']").val("");
                sessionStorage.setItem("Build_Unknown__c", "on");
                $("[name='Year_Built__c']").prop("disabled", true);
            }
            else {
                sessionStorage.setItem("Build_Unknown__c", "off");
                $("[name='Year_Built__c']").prop("disabled", false);
            }
        })


        function clearInputValues(id) {
            if (id) {
                let select = $(id).find("select");
                let input = $(id).find("input");
                if (select && select.length > 0) {
                    $(select).val("");
                    let name = $(select).attr("name");
                    sessionStorage.removeItem(name);
                }
                if (input && input.length > 0) {
                    $(input).val("");
                    let name = $(input).attr("name");
                    sessionStorage.removeItem(name);
                }

            }

        }

        function hideRelatedQuestion(arr) {
            if (arr && arr.length > 0) {

                for (let i = 0; i < arr.length - 1; i++) {
                    //clear the session values

                    $(`[data-parent=${arr[i]}]`).removeClass("active");
                    clearInputValues($(`[data-parent=${arr[i]}]`))
                }
            }
        }

        //disable character keys  
        $("[name='Year_Built__c']").keypress(function (key) {

            if (key.charCode == 8 || key.keyCode == 8) {
                return true;
            } else if (key.charCode < 48 || key.charCode > 57) {
                return false;
            }
        });




        let leadFormElement = $("#LeadForm select, #LeadForm input");

        for (let i = 0; i < leadFormElement.length; i++) {
            let name_with_period = $(leadFormElement[i]).attr("name");
            let name_with_underscore = name_with_period.replace(".", "_");
            $(leadFormElement[i]).attr("name", name_with_underscore);

        }
    })

},{}],3:[function(require,module,exports){
var HWPLeadForm = require("./Components/Feature/DSM/HWPLeadForm.js");
var HWPscreensScript = require("./Components/Feature/DSM/HWPscreensScript.js");
},{"./Components/Feature/DSM/HWPLeadForm.js":1,"./Components/Feature/DSM/HWPscreensScript.js":2}]},{},[3]);
